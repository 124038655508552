<template>
  <div class="pageHeader">
    <label id="headerText" class="text">{{ $t(isEmptyString(text) ? "" : "tableNames." + text) }}</label>
    <div class="userInfo end" @click="profile">
      <div v-if="!isEmptyString(fullName)" class="fullName">
        <label class="text">{{ fullName }}</label>
      </div>
      <div v-if="!isEmptyString(userName)" class="userName">
        <label class="text">{{ userName }}</label>
      </div>
    </div>
    <div class="icon end" @click="profile">
      <span class="fa fa-user-circle"></span>
    </div>
    <div class="input-field col end">
      <select id="localeSwitcher" v-model="$i18n.locale" @change="changeLocalization">
        <option value="en">English</option>
        <option value="de">Deutsch</option>
      </select>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
import Link from "./utils/Link";
import ui from "./utils/ui";
import axios from "axios";
import {GlobalLocalizationEventEmitter} from "@/i18n";

export default {
  name: "PageHeader",
  props: {
    text: {
      type: String,
      default: "none"
    }
  },
  mounted() {
    this.getUser();
  },
  data() {
    return {
      fullName: "",
      userName: ""
    }
  },
  methods: {
    profile() {
      if (this.$route.path !== "/profile")
        this.$router.push({name: 'profile'})
    },

    changeLocalization() {
      window.localStorage.setItem("locale", $('#localeSwitcher').val())
      GlobalLocalizationEventEmitter.$emit("localizationChanged");
    },

    isEmptyString(value) {
      return ui.methods.isEmptyString(value);
    },

    getUser() {
      const headers = Link.methods.getHeaders();
      const userId = window.localStorage.getItem("id");
      if (userId != null) {
        axios.get(
            Link.methods.getUserByIdUrl(userId),
            {headers}
        ).then(res => {
          const firstName = res.data.firstName;
          const lastName = res.data.lastName;
          const userName = res.data.username;

          if (!this.isEmptyString(firstName)) {
             if (!this.isEmptyString(lastName)) {
               this.fullName = firstName + ' ' + lastName;
             } else {
               this.fullName = firstName;
             }
          } else {
            this.fullName = lastName;
          }

          if (!this.isEmptyString(userName)) {
            if (!this.isEmptyString(this.fullName)) {
              this.userName = '(' + userName + ')';
            } else {
              this.userName = userName;
            }
          } else {
            this.userName = '';
          }
        }).catch((e) => {
          console.error(e);
        })
      }
    }
  }
}
</script>

<style scoped>
@import "../../public/styles/pageHeader.scss";

select {
  width: 150px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 14px;
}

select:focus-visible {
  outline: none;
}
</style>
<script>
export default {
  methods: {

    checkTokenExp() {
      const token = this.getToken();
      if (token) {
        if (Math.trunc(Date.now() / 1000) > this.parseJwt(this.getToken()).exp) {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('id');
          //this.checkAuthorized()
          this.createToast("Your token is invalid", "error")
          return false;
        } else {
          return true;
        }
      }
      return false;
    },

    parseJwt(token) {
      let base64 = token.split('.')[1];
      return this.createJson(base64);
    },

    createJson(base64Url) {
      return JSON.parse(atob(base64Url));
    },

    getToken() {
      return window.localStorage.getItem('token');
    },

    getUrl() {
      const PORT = ""; // ":32080";
      const PATH = "/api/v1"
      return window.location.protocol + "//" + window.location.hostname + PORT + PATH;
    },

    getAuthenticationUrl() {
      return this.getUrl() + "/auth/login";
    },

    getItemsAllUrl() {
      return this.getUrl() + "/articles/all"
    },

    getItemsCreateUrl() {
      return this.getUrl() + "/articles/create"
    },

    getItemUpdate() {
      return this.getUrl() + "/articles/update"
    },

    getItemById(id) {
      return this.getUrl() + '/articles/id/' + id
    },

    getItemsByManufacturer(id) {
      return this.getUrl() + "/articles/filterByManufacturer/" + id;
    },

    getMountTypes() {
      return this.getUrl() + "/articles/getmounttypes/"
    },

    getItemDeleteUrl(id) {
      return this.getUrl() + '/articles/delete/' + id
    },

    getItemRestoreUrl(id) {
      return this.getUrl() + "/articles/restore/" + id;
    },


    getManufacturerUpdate() {
      return this.getUrl() + "/manufactures/update"
    },

    getManufacturerById(id) {
      return this.getUrl() + '/manufactures/id/' + id
    },

    getManufacturesUrl() {
      return this.getUrl() + "/manufactures/all"
    },

    getManufacturesCreateUrl() {
      return this.getUrl() + "/manufactures/create"
    },

    getManufacturerDeleteUrl(id) {
      return this.getUrl() + "/manufactures/delete/" + id;
    },

    getManufacturerRestoreUrl(id) {
      return this.getUrl() + "/manufactures/restore/" + id;
    },

    getTicketsUrl() {
      return this.getUrl() + "/tickets/all"
    },

    getAllUsersUrl() {
      return this.getUrl() + "/users/all"
    },

    getSystemUserUrl() {
      return this.getUrl() + "/users/system"
    },

    getUserCreateUrl() {
      return this.getUrl() + "/users/create"
    },

    getNewUserUrl() {
      return this.getUrl() + "/admin/users/create"
    },

    getUserByIdUrl(id) {
      return this.getUrl() + "/users/" + id
    },

    getUserUpdateByIdUrl() {
      return this.getUrl() + "/admin/update"
    },

    getRoleUpdateUrl(id, role) {
      return this.getUrl() + "/admin/updaterole/" + id + '/' + role
    },

    getUserUpdateUrl() {
      return this.getUrl() + "/users/update"
    },

    getUserChangePasswordUrl() {
      return this.getUrl() + "/users/changepassword"
    },

    getUserDeleteUrl(userId) {
      return this.getUrl() + "/admin/users/delete/" + userId;
    },

    getTicketCreateUrl() {
      return this.getUrl() + "/tickets/create"
    },

    getHeaders() {
      return {
        "Authorization": this.getToken(),
        "Content-Type": "application/json"
      };
    },

    getDeleteTicketsUrl(id) {
      return this.getUrl() + "/tickets/delete/" + id;
    },

    getUserTicketsUrl() {
      return this.getUrl() + "/tickets/usertickets";
    },

    getTicketTypesUrl() {
      return this.getUrl() + "/tickets/gettickettypes";
    },

    getTicketByIdUrl(id) {
      return this.getUrl() + "/tickets/id/" + id;
    },

    getTicketUpdateUrl() {
      return this.getUrl() + "/tickets/update"
    },

    getTicketStatusChangeUrl(id, status) {
      return this.getUrl() + '/tickets/updatestatus/' + id + '/' + status;
    },

    getTicketRulesUrl() {
      return this.getUrl() + '/ticketrules/all';
    },

    getFilePhotoUploadUrl() {
      return this.getUrl() + '/file/uploadphoto';
    },

    getStatusesUrl() {
      return this.getUrl() + '/settings/get-ticket-statuses';
    },

    getOneTicketRuleUrl(id) {
      return this.getUrl() + '/ticketrules/id/' + id;
    },

    getTicketRuleEditUrl() {
      return this.getUrl() + '/ticketrules/update'
    },

    getTicketRuleCreateUrl() {
      return this.getUrl() + '/ticketrules/create';
    },

    getTicketRuleDeleteUrl(id) {
      return this.getUrl() + '/ticketrules/delete/' + id;
    },

    getHistoryLogsUrl() {
      return this.getUrl() + '/logs/all';
    },

    getNewStatusTicketCreateUrl() {
      return this.getUrl() + '/settings/create-ticket-status';
    },

    getNewTypeTicketCreateUrl() {
      return this.getUrl() + '/settings/create-ticket-type';
    },

    getTicketSettingStatusesUrl() {
      return this.getUrl() + '/settings/get-ticket-statuses';
    },

    getTicketSettingTypesUrl() {
      return this.getUrl() + '/settings/get-ticket-types';
    },

    getTicketSettingStatusDelete(id) {
      return this.getUrl() + '/settings/delete-ticket-status/' + id;
    },

    getTicketSettingTypeDelete(id) {
      return this.getUrl() + '/settings/delete-ticket-type/' + id;
    },

    getItemLogs(id) {
      return this.getUrl() + '/logs/item/' + id + '/userlogs';
    },

    getTicketLogs(id) {
      return this.getUrl() + '/logs/ticket/' + id + '/userlogs';
    },

    getManufacturerLogs(id) {
      return this.getUrl() + '/logs/manufacturer/' + id + '/userlogs';
    },

    getSingleHistoryLog(id) {
      return this.getUrl() + '/logs/id/' + id;
    },

    getExportCsvUrl(tableName) {
      return this.getUrl() + '/file/csv-download/' + tableName;
    },

    getUserActivateLink(id) {
      return this.getUrl() + "/tickets/useractivate/" + id;
    },

    getItemFromTicketCreateUrl(id) {
      return this.getUrl() + "/tickets/newarticle/" + id;
    },

    getAppStateUrl(userName) {
      return this.getUrl() + "/state/getappstate/" + userName;
    }
  }
}
</script>

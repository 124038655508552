import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
    App.methods.checkAuthorized();
    return next();
})
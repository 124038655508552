<template>
  <div id="registration">
    <select id="localeSwitcher" v-model="$i18n.locale" @change="changeLocalization">
      <option value="en">English</option>
      <option value="de">Deutsch</option>
    </select>
    <div class="form-container" @keyup.enter="sendCreateRequest">
      <form autocomplete="off">
        <div class="input-field col">
          <i class="fa fa-user-circle-o prefix"></i>
          <label v-if="!isUsernameValid" for="user-name" class="active invalid_value">
            {{ $t(isUsernameFree ? "registration.enterUsername" : "registration.usernameAlreadyExists") }}
          </label>
          <label v-else for="user-name" class="active">{{ $t("registration.userName") }}</label>
          <input id="user-name" type="text" v-model="request.username">
        </div>
        <div class="input-field col">
          <i class="fa fa-user-o prefix"></i>
          <input id="first-name" type="text" v-model="request.firstName">
          <label for="first-name" class="active">{{ $t("registration.firstName") }}</label>
        </div>
        <div class="input-field col">
          <i class="fa fa-user-o prefix"></i>
          <input id="last-name" type="text" v-model="request.lastName">
          <label for="last-name" class="active">{{ $t("registration.lastName") }}</label>
        </div>
        <div class="input-field col">
          <i class="fa fa-envelope prefix"></i>
          <label v-if="!isEmailValid" for="email" class="active invalid_value">
            {{ $t(isEmailFree ? "registration.enterEmail" : "registration.emailAlreadyExists") }}
          </label>
          <label v-else for="email" class="active">{{ $t("registration.email") }}</label>
          <input id="email" type="text" v-model="request.email">
        </div>
        <div class="input-field col">
          <i class="fa fa-lock prefix"></i>
          <label v-if="!isPasswordValid" for="password" class="active invalid_value">
            {{ $t("registration.enterPassword") }}
            <i class="fa fa-eye right" @click="showPassword"></i>
          </label>
          <label v-else for="password" class="active">
            {{ $t("registration.password") }}
            <i class="fa fa-eye right" @click="showPassword"></i>
          </label>
          <input id="password" type="password" v-model="request.password">
        </div>
        <div class="input-field col">
          <i class="fa fa-lock prefix"></i>
          <label v-if="!isPassword2Valid" for="password2" class="active invalid_value">
            {{ $t("registration.passwordsAreNotEqual") }}
            <i class="fa fa-eye right" @click="showPassword2"></i>
          </label>
          <label v-else for="password2" class="active">
            {{ $t("registration.password2") }}
            <i class="fa fa-eye right" @click="showPassword2"></i>
          </label>
          <input id="password2" type="password" v-model="request.password2">
        </div>
        <div class="input-field col">
          <i class="fa fa-ticket prefix"></i>
          <input id="notes" type="text" v-model="request.notes">
          <label for="notes" class="active">{{ $t("registration.registrationReason") }}</label>
        </div>
        <div class="controllers">
          <button type="button" id="back-button" @click="backToAuthorization">
            <i class="fa fa-angle-left right"></i>
            <label id="back" for="back">{{ $t("registration.back") }}</label>
          </button>
          <button type="button" id="sign-up-register" @click="sendCreateRequest">
            <i class="fa fa-plus right"></i>
            <label id="signUp" for="sign-up-register">{{ $t("registration.signUp") }}</label>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import Link from "./utils/Link";
import ui from "./utils/ui";
import $ from "jquery";

export default {
  name: "Registration",

  data() {
    return {
      request: {
        password: "",
        password2: "",
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        notes: ""
      },
      isUsernameValid: true,
      isEmailValid: true,
      isPasswordValid: true,
      isPassword2Valid: true,
      isUsernameFree: true,
      isEmailFree: true
    }
  },

  mounted() {
    window.scrollTo(0, 0);
    this.updateFieldsFromLoginForm();
  },

  methods: {
    updateFieldsFromLoginForm() {
      let savedItem = window.localStorage.getItem("regData");
      if (savedItem) {
        const data = JSON.parse(savedItem)
        if (data != null) {
          if (data.username != null) {
            this.request.username = data.username;
          }
          if (data.password != null) {
            this.request.password = data.password;
          }
          this.checkFields();
        }
        window.localStorage.removeItem("regData");
      }
    },

    showPassword() {
      let raw = document.getElementById("password");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    showPassword2() {
      let raw = document.getElementById("password2");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    changeLocalization() {
      window.localStorage.setItem("locale", $('#localeSwitcher').val())
    },

    hidePasswords() {
      let raw1 = document.getElementById("password");
      let raw2 = document.getElementById("password2");
      raw1.type = "password";
      raw2.type = "password";
    },

    checkFields() {
      this.isEmailValid = ui.methods.checkEmail(this.request.email);
      this.isPasswordValid = (this.request.password != null && this.request.password.trim().length > 5);
      this.isPassword2Valid = (this.request.password === this.request.password2)
      this.isUsernameValid = this.request.username.length > 0;

      return (
          this.isEmailValid
          && this.isPasswordValid
          && this.isUsernameValid
          && this.isPassword2Valid
      );
    },

    sendCreateRequest() {
      this.hidePasswords();

      if (this.checkFields()) {
        const headers = Link.methods.getHeaders();
        axios.post(
            Link.methods.getUserCreateUrl(),
            this.request,
            {headers}
        ).then(() => {
          this.createToast(this.$t("registration.createToast"), "success")
          this.backToAuthorization();
          this.isUsernameFree = true;
          this.isEmailFree = true;
        }).catch((reason) => {
          this.isEmailFree = true;
          this.isUsernameFree = true;
          if (reason.response.status === 406) {
            // username already exists
            this.isUsernameFree = false;
            this.isUsernameValid = false;
            this.createToast(this.$t("registration.usernameAlreadyExistsToast", [this.request.username]), "error");
          } else if (reason.response.status === 409) {
            // email already exists
            this.isEmailFree = false;
            this.isEmailValid = false;
            this.createToast(this.$t("registration.emailAlreadyExistsToast", [this.request.email]), "error");
          } else {
            // other errors
            this.isUsernameValid = false;
            this.createToast(this.$t("registration.errorToast", [this.request.username]), "error");
          }
        });
      } else {
        this.createToast(this.$t("registration.invalidFieldsToast"), "error");
      }
    },

    backToAuthorization() {
      this.$emit('authorization');
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../public/materialize-src/sass/materialize.scss';
@import '../../public/styles/authorization_register.scss';
</style>
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'dashboard',
            path: '/',
            component: () => import('./components/dashboard/Dashboard'),
            props: true
        },
        {
            name: 'items',
            path: '/items',
            component: () => import ('./components/item/MainItem'),
            props: true
        },
        {
            name: 'manufacturers',
            path: '/manufacturers',
            component: () => import ('./components/manufacturer/ManufacturersBoard'),
        },
        {
            name: 'tickets',
            path: '/tickets',
            component: () => import('./components/ticket/MainTicket')
        },
        {
            name: 'addTicket',
            path: '/tickets/new',
            component: () => import('./components/ticket/AddTicket')
        },
        {
            name: 'addItem',
            path: '/items/new',
            component: () => import ('./components/item/AddItem')
        },
        {
            name: 'newManufacturer',
            path: '/manufacturers/new',
            component: () => import ('./components/manufacturer/AddManufacturer')
        },
        {
            name: 'newItem',
            path: '/addItem/',
            component: () => import('./components/item/AddItem'),
            props: true,
        },
        {
            name: 'users',
            path: '/users',
            component: () => import ('./components/user/MainUser')
        },
        {
            name: 'user',
            path: '/users/:id',
            component: () => import ('./components/user/SingleUser'),
            props: true,
        },
        {
            name: 'profile',
            path: '/profile',
            component: () => import ('./components/MainUserProfile')
        },
        {
            name: "itemById",
            path: '/items/:id',
            component: () => import ('./components/item/SingleItem.vue'),
            props: true
        },
        {
            name: "editItem",
            path: '/items/:id/edit',
            component: () => import ('./components/item/SingleItem.vue'),
            props: true
        },
        {
            name: 'manufacturerById',
            path: '/manufacturers/:id',
            component: () => import ('./components/manufacturer/SingleManufacturer'),
            props: true
        },
        {
            path: '/items2',
            name: 'byManufacturer',
            component: () => import ('./components/item/ItemsByManufacturerId.vue'),
            props: true
        },
        {
            name: 'ticketById',
            path: '/tickets/:id',
            component: () => import('./components/ticket/SingleTicket'),
            props: true
        },
        {
            name: 'ticketRules',
            path: '/ticketRules',
            component: () => import('./components/ticketRule/MainTicketRule'),
            props: true
        },
        {
            name: 'addTicketRule',
            path: '/ticketRules/new',
            component: () => import('./components/ticketRule/AddTicketRule'),
            props: true
        },
        {
            name: 'ticketRulesById',
            path: '/ticketRules/:id',
            component: () => import('./components/ticketRule/SingleTicketRule'),
            props: true
        },
        {
            name: 'dictionary',
            path: '/dictionary',
            component: () => import('./components/dictionary/Dictionary')
        },
        {
            name: 'historyLog',
            path: '/historyLogs',
            component: () => import('./components/historyLog/MainHistoryLog')
        },
        {
            name: 'logById',
            path: '/historyLogs/:id',
            component: () => import('./components/historyLog/SingleHistoryLog')
        },
        {
            name: 'calibration',
            path: '/calibration',
            component: () => import('./components/calibration/Calibration')
        }
    ]
})


<template>
  <div id="menuBar">
    <sidebar-menu :menu="menu"
                  :collapsed="collapsed"
                  :show-one-child="true"
                  @toggle-collapse="onToggleCollapse"
                  @item-click="onItemClick">
      <template v-slot:header>
        <div v-show="!collapsed">
          <img class="logo" src="/regalsteger-logo.png" alt="Logo">
        </div>
        <div v-show="collapsed">
          <img class="littleLogo" src="/LittleLogo.png" alt="Logo">
        </div>
      </template>
    </sidebar-menu>
  </div>
</template>

<script>
import {SidebarMenu} from 'vue-sidebar-menu'
import Link from "./utils/Link";
import $ from 'jquery'
import axios from "axios";

export default {
  components: {
    SidebarMenu
  },

  beforeMount() {
    this.restoreCollapsedState();
  },

  mounted() {
    this.onToggleCollapse(this.collapsed);
    this.setMenu();
    this.getApkVersion();
  },

  methods: {
    onToggleCollapse(new_state) {
      $(window).scrollTop($(window).scrollTop() - 1);

      let component = document.getElementById("components");
      let header = document.getElementById("pageHeader");

      this.collapsed = new_state;
      if (this.collapsed) {
        header.classList.add("collapsed-components");
        component.classList.add("collapsed-components");
        $('.vsm--toggle-btn').css('transform', 'rotate(180deg)');
      } else {
        header.classList.remove("collapsed-components");
        component.classList.remove("collapsed-components");
        $('.vsm--toggle-btn').css('transform', 'rotate(360deg)');
      }

      this.saveCollapsedState()
    },

    onItemClick(event, item) {
      if (item.title === this.$t("navBar.logout")) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('id');
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
        this.$emit("logout");
      }
      if (item.title === this.$t('navBar.downloadAPK')) {
        window.location = `files//APK/rkimobile-${this.apkVersion}.apk`;
      }
      if (item.title === this.$t("navBar.qrCode")) {
        window.location = '/marker-RS30m.pdf';
      }
    },

    isRoleAdmin() {
      const u = Link.methods.parseJwt(Link.methods.getToken());
      return (u.roles === 'ADMIN');
    },

    getApkVersion() {
      const username = Link.methods.parseJwt(Link.methods.getToken()).sub;
      const headers = Link.methods.getHeaders();
      axios.get(Link.methods.getAppStateUrl(username), {headers}).then(res => {
        this.apkVersion = res.data.v;
      })
    },

    saveCollapsedState() {
      window.localStorage.setItem("sidebarcollapsed", this.collapsed);
    },

    restoreCollapsedState() {
      let savedItem = window.localStorage.getItem("sidebarcollapsed");
      if (savedItem) {
        this.collapsed = (savedItem === 'true');
      }
    },

    setMenu() {
      let menu = [
        {
          header: true,
        },
        {
          href: '/',
          title: this.$t('navBar.dashboard'),
          icon: 'fa fa-th'
        },
        {
          href: '/tickets',
          title: this.$t("navBar.tickets"),
          icon: 'fa fa-ticket'
        },
        {
          href: '/items',
          title: this.$t("navBar.items"),
          icon: 'fa fa-object-group'
        },
        {
          href: '/manufacturers',
          title: this.$t("navBar.manufacturers"),
          icon: 'fa fa-briefcase'
        },
        {
          href: '/profile',
          title: this.$t("navBar.profile"),
          icon: 'fa fa-vcard'
        },
        {
          title: this.$t("navBar.downloads"),
          icon: 'fa fa-download',
          child: [
            {
              title: this.$t("navBar.downloadAPK"),
              icon: 'fa fa-download'
            },
            {
              href: "/calibration",
              title: this.$t("navBar.cameraCalibration"),
              icon: "fa fa-compass"
            },
            {
              title: this.$t("navBar.qrCode"),
              icon: "fa  fa-qrcode"
            },
          ]
        },
        {
          title: this.$t("navBar.logout"),
          icon: 'fa fa-sign-out'
        },
      ];
      if (this.isRoleAdmin()) {
        menu.splice(6, 0, {
          title: this.$t("navBar.settings"),
          icon: 'fa fa-cog',
          child: [
            {
              href: '/users',
              title: this.$t("navBar.users"),
            },
            {
              href: '/ticketRules',
              title: this.$t("navBar.ticketRules"),
            },
            /*{
              href: '/dictionary',
              title: this.$t("navBar.dictionary")
            },*/
            {
              href: '/historyLogs',
              title: this.$t("navBar.historyLogs")
            }
          ]
        });
      }
      return menu;
    }
  },

  data() {
    return {
      menu: this.setMenu(),
      apkVersion: '',
      themes: [
        {
          name: 'Default theme',
          input: ''
        },
        {
          name: 'White theme',
          input: 'white-theme'
        }
      ],
      isOnMobile: false,
      collapsed: false
    }
  }
}
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');
@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
@import "../../public/styles/vars.scss";

#components.collapsed-components {
  padding-left: 64px;
}

#pageHeader.collapsed-components {
  padding-left: 57px;
}

.logo {
  margin-top: 16px;
  margin-left: 10px;
  width: 85%;
  height: 34px;
}

.littleLogo {
  margin-top: 16px;
  margin-left: 11px;
  width: 34px;
  height: 34px;
}

.v-sidebar-menu .vsm--toggle-btn:after {
  content: '\f053';
  font-family: 'FontAwesome';
}

.v-sidebar-menu .vsm--arrow:after {
  content: '\f105';
  font-family: 'FontAwesome';
}

.v-sidebar-menu {
  background: $main-color;
  font-family: BloggerSans,sans-serif;
}

.v-sidebar-menu.vsm_expanded {
  width: 224px;
}

.v-sidebar-menu.vsm_collapsed {
  max-width: 64px !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--list {
  width: 64px;
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
}

.v-sidebar-menu .vsm--mobile-item {
  width: 244px !important;
}

.v-sidebar-menu .vsm--mobile-bg {
  background-color: $main-color;
}

.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon, .v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  background-color: $main-color;
  color: $second-color;
}

.v-sidebar-menu .vsm--link_hover, .v-sidebar-menu .vsm--link:hover {
  background-color: $main-color;
}

.v-sidebar-menu .vsm--link, .v-sidebar-menu .vsm--link_level-2 {
  color: $second-color;
}

.v-sidebar-menu .vsm--dropdown .vsm--list {
  background-color: $main-color;
  width: 194px;
}

.v-sidebar-menu .vsm--dropdown::-webkit-scrollbar {
  display: none;
}

.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  background-color: $main-color;
  color: $second-color;
}

.v-sidebar-menu .vsm--link_level-1 .vsm--icon, .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: $main-color;
}

.v-sidebar-menu .vsm--dropdown {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.v-sidebar-menu .vsm--link.vsm--link_exact-active {
  color: $third-color;
}

.v-sidebar-menu .vsm--icon {
  background: $main-color;
}

#menuBar {
  height: 100%;
}

.v-sidebar-menu .vsm--toggle-btn {
  padding-top: 0;
  background-color: $main-color;
  color: $second-color
}

.v-sidebar-menu .vsm--toggle-btn:focus {
  outline: none;
}

.v-sidebar-menu .vsm--scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.v-sidebar-menu .vsm--scroll-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

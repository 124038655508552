<template>
  <div id="auth">
    <select id="localeSwitcher" v-model="$i18n.locale" @change="changeLocalization">
      <option value="en">English</option>
      <option value="de">Deutsch</option>
    </select>
    <div class="form-container">
      <form autocomplete="off" @keyup.enter="sendTokenRequest">
        <div class="input-field col">
          <i class="fa fa-user-circle-o prefix"></i>
          <input id="user-name" type="text" v-model="request.username">
          <label for="user-name" class="active">{{ $t("authorization.userName") }}</label>
        </div>

        <div class="input-field col">
          <i class="fa fa-lock prefix"></i>
          <input id="password" type="password" v-model="request.password">
          <label for="password" class="active">{{ $t("authorization.password") }}
            <i class="fa fa-eye right" @click=showPassword></i>
          </label>
        </div>

        <vue-confirm-dialog></vue-confirm-dialog>

        <div class="controllers">
          <button type="button" id="sign-in" @click="sendTokenRequest">
            <i class="fa fa-check right"></i>
            <label id="signIn" for="sign-in">{{ $t("authorization.signIn") }}</label>
          </button>
          <button type="button" id="sign-up" @click="goToRegistration">
            <i class="fa fa-plus right"></i>
            <label id="signUp" for="sign-up">{{ $t("authorization.signUp") }}</label>
          </button>
        </div>
      </form>
      <div id="forget-password" @click="forgotPassword">{{ $t("authorization.forgotPassword") }}</div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import VueConfirmDialog from "vue-confirm-dialog";
import Link from "./utils/Link";
import ui from "./utils/ui";
import $ from "jquery";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default {
  name: "Authorization",

  data() {
    return {
      request: {
        username: '',
        password: '',
        email: "",
        addinfo: ""
      }
    }
  },

  mounted() {
    this.loadAdminInfo();
    window.scrollTo(0, 0)
  },

  methods: {
    showPassword() {
      let raw = document.getElementById("password");
      if (raw.type === "password") {
        raw.type = "text";
      } else {
        raw.type = "password";
      }
    },

    changeLocalization() {
      window.localStorage.setItem("locale", $('#localeSwitcher').val())
    },

    sendTokenRequest() {
      axios.post(Link.methods.getAuthenticationUrl(), this.request).then((res) => {
        if (Link.methods.parseJwt(res.data.token).roles !== 'GUEST') {
          window.localStorage.setItem('token', `Bearer_` + res.data.token);
          window.localStorage.setItem('id', Link.methods.parseJwt(res.data.token).id);
          window.localStorage.setItem('userName', Link.methods.parseJwt(res.data.token).sub);
          this.$emit('authorized');
        } else {
          this.$emit('not-allowed-page');
        }
      }).catch(() => {
        this.createToast(this.$t("authorization.errorToast"), "error");
      });
    },

    loadAdminInfo()
    {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getSystemUserUrl(),
          {headers}
      ).then((res) => {
        const email = res.data.email;
        const fname = res.data.firstName;
        const lname = res.data.lastName;
        let addinfo = ''
        if (fname) {
          if (lname) {
            addinfo = '(' + lname + ', ' + fname + ')';
          } else {
            addinfo = '(' + fname + ')';
          }
        } else if (lname) {
          addinfo = '(' + lname + ')';
        }
        this.email = (email) ? ' ' + email : '';
        this.addinfo = (addinfo) ? ' ' + addinfo : '';
      }).catch((e) => {
        console.error(e);
      })
    },

    forgotPassword() {
      this.$confirm(
          {
            title: this.$t("authorization.forgotPasswordTitle"),
            message: this.$t("authorization.forgotPasswordMessage", [this.email, this.addinfo]),
            button: {
              yes: this.$t("authorization.continue")
            }
          }
      )
    },

    goToRegistration() {
      const username = ui.methods.isEmptyString(this.request.username) ? undefined : this.request.username;
      const pass = ui.methods.isEmptyString(this.request.password) ? undefined : this.request.password;
      if (username || pass) {
        const ticketData = {
          "username":  username,
          "password": pass,
        };
        const savedData = JSON.stringify(ticketData);
        window.localStorage.setItem("regData", savedData);
      }

      this.$emit('registration');
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },
  },
}
</script>


<style scoped lang="scss">
@import '../../public/materialize-src/sass/materialize.scss';
@import '../../public/styles/authorization_register.scss';

#forget-password {
  margin-top: 20px;
}

#forget-password:hover {
  color: red;
  cursor: pointer;
}

</style>
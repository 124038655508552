<script>
export default {
  methods: {
    isEmptyString(value) {
      return value == null || value.length === 0;
    },

    isEmptyNumber(value) {
      return value == null
          || isNaN(value)
          || (typeof value == 'number' && value === 0)
          || (typeof value == 'string' && value.length === 0);
    },

    fmt(value) {
      return this.isEmptyNumber(value) ? undefined : (+value > 0 ? +value : undefined);
    },

    checkEmail(str) {
      const regex = new RegExp('(?:[a-z0-9+!#$%&\'*/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])', 'gi');
      return regex.test(str);
    },

    stickHeader(tableHeaderPosition) {
      let tableHeader = document.getElementById("tableHead");
      let table = document.getElementById("table");
      let tableControlHeader = document.getElementById("header");

      const scrollY = window.scrollY;
      const tableHeight = table.offsetHeight  + tableControlHeader.offsetHeight;
      const topHeight = tableHeader.offsetHeight + tableControlHeader.offsetHeight;
      const isStickyMode = (tableControlHeader.classList.contains('sticky'));

      if (!isStickyMode) {
        tableHeaderPosition[0] = tableControlHeader.offsetTop;
      }
      let sticky = tableHeaderPosition[0];

      if (
          scrollY > sticky
          && !isStickyMode
          && tableHeight > window.innerHeight
      ) {
        tableControlHeader.classList.add('sticky');
        tableHeader.style.top = tableControlHeader.offsetHeight + 'px';
        tableHeader.style.position='fixed';
        table.style.paddingTop = topHeight + 'px';
        if (tableHeight - topHeight < window.innerHeight) {
          window.scroll(window.scrollX, scrollY);
        }
      } else if (
          scrollY < sticky
          && isStickyMode
      ) {
        tableControlHeader.classList.remove('sticky');
        tableHeader.style = undefined;
        table.style = undefined;
      }
    },

    copyElementWidthIfDifferent(fromElementSelector, toElementSelector) {
      let width = -1;

      if (typeof fromElementSelector === 'number') {
        width = fromElementSelector;
      } else {
        const fromElement = document.querySelector(fromElementSelector);
        if (fromElement) {
          width = fromElement.offsetWidth;
        }
      }

      if (width >= 0) {
        const toElement = document.querySelector(toElementSelector);
        if (toElement) {
          const leftElement = toElement.querySelector('.left');
          if (leftElement) {
            const leftMaxWidth = (width - 16) + 'px';
            if (leftMaxWidth !== leftElement.style['maxWidth']) {
              leftElement.style.maxWidth = leftMaxWidth;
            }
          }

          const fromWidth = width + 'px';
          if (fromWidth !== toElement.style['width']) {
            toElement.style.width = fromWidth;
            toElement.style.maxWidth = fromWidth;
          }
        }
      }

      return width > 0 ? width : 0;
    },
  }
}
</script>
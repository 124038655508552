<template>
  <div id="app">
    <div v-if="authorized">
      <div id="pageHeader" class>
        <page-header v-bind:text="text" :key="updateKey"/>
      </div>
      <div id="components" class>
        <router-view @changeComponent="changeComponent" @updateUserInfo="updateUserInfo"/>
      </div>
      <SideBarMenu @logout="checkAuthorized" :key="localizationChangedKey"/>
    </div>
    <div class="screen" v-else>
      <Authorization v-if="!auth"
                     @registration="changeAuthorizationRegistrationPage"
                     @not-allowed-page="changeOnNotAllowed"
                     @authorized="checkAuthorized"/>
      <Registration @authorization="changeAuthorizationRegistrationPage" v-else-if="auth && !notAllowedPage"/>
      <NotAllowedPage @authorization="changeOnNotAllowed" v-else/>
    </div>
  </div>
</template>
<script>

import SideBarMenu from "./components/SideBarMenu";
import $ from 'jquery'
import PageHeader from "./components/PageHeader";
import Authorization from "./components/Authorization";
import Registration from "./components/Registration";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import Link from "./components/utils/Link";
import NotAllowedPage from "./components/NotAllowedPage";
import {GlobalLocalizationEventEmitter} from "@/i18n";

export default {
  name: 'App',
  components: {
    Authorization,
    Registration,
    PageHeader,
    SideBarMenu,
    NotAllowedPage
  },

  data() {
    return {
      items: [],
      text: "none",
      authorized: false,
      auth: false,
      notAllowedPage: false,
      localizationChangedKey: 0,
      callback: undefined,
      updateKey: 0
    };
  },

  beforeMount() {
    this.text = window.localStorage.getItem("headerTitle");
  },

  mounted() {
    this.resizing();
    this.authorized = Link.methods.checkTokenExp();
  },

  created() {
    window.addEventListener("resize", this.resizing);
    this.callback = GlobalLocalizationEventEmitter.$on("localizationChanged", () => {
      this.localizationChangedKey += 1;
    })
  },

  beforeDestroy() {
    GlobalLocalizationEventEmitter.$off("localizationChanged", this.callback);
  },

  methods: {
    checkAuthorized() {
      this.authorized = Link.methods.checkTokenExp()
    },

    changeAuthorizationRegistrationPage() {
      this.auth = !this.auth;
    },

    changeOnNotAllowed() {
      this.auth = !this.auth;
      this.notAllowedPage = !this.notAllowedPage;
    },

    compare: function (a, b, order) {
      let sign = order ? 1 : -1;

      if (a == null && b == null) {
        return -1 * sign;
      }
      if (a == null) {
        return 1 * sign;
      }
      if (b == null) {
        return -1 * sign;
      }

      if (isNaN(a) && isNaN(b)) {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1 * sign;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1 * sign;
        }
      } else {
        if (a < b) {
          return -1 * sign;
        }
        if (a > b) {
          return 1 * sign;
        }
      }
      return 0;
    },

    comparator: function (getter, order) {
      return (a, b) => this.compare(getter(a), getter(b), order);
    },

    changeComponent(text) {
      this.text = text;
      window.localStorage.setItem("headerTitle", text);
    },

    updateUserInfo() {
      this.updateKey += 1;
    },

    resizing() {
      if (this.authorized) {
        let sideBar = $('#menuBar').find(".v-sidebar-menu")[0];
        let btn = $('.v-sidebar-menu').find(".vsm--toggle-btn")[0];
        if (window.innerWidth <= 1200 && sideBar.classList.contains("vsm_expanded")) {
          btn.click();
          btn.style.display = "none";
        } else if (window.innerWidth <= 1200 && sideBar.classList.contains("vsm_collapsed")) {
          btn.style.display = "none";
        } else if (window.innerWidth >= 1200 && sideBar.classList.contains("vsm_collapsed")) {
          btn.style.display = "block";
        }
      }
    },

    /**
     * Creates a toast notification.
     * @param {string} msg - The message to display in the toast.
     * @param {string} type - The type of the toast (e.g., 'success', 'error').
     */
    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    linkToTicket: function (id) {
      return "<a href=/tickets/" + id + ">" + "Ticket " + id + "</a>";
    },

    linkToItem: function (id) {
      return "<a href=/items/" + id + ">" + "Item " + id + "</a>";
    },

    linkToManufacturer: function (id) {
      return "<a href=/manufacturers/" + id + ">" + "Manufacturer " + id + "</a>";
    },

    replaceTicketLinks: function (item) {
      const regexp = new RegExp("<ticket#(\\d+)>");
      const match = item.match(regexp);
      if (match) {
        return item.replace(regexp, this.linkToTicket(match[1]));
      }
      return item;

    },

    replaceItemLinks: function (item) {
      const regexp = new RegExp("<Item#(\\d+)>");
      const match = item.match(regexp);
      if (match) {
        return item.replace(regexp, this.linkToItem(match[1]));
      }
      return item;
    },

    replaceManufacturerLinks: function (item) {
      const regexp = new RegExp("<Manufacturer#(\\d+)>");
      const match = item.match(regexp);
      if (match) {
        return item.replace(regexp, this.linkToManufacturer(match[1]));
      }
      return item;
    },

    getLink(item) {
      let temp = this.replaceTicketLinks(item);
      temp = this.replaceItemLinks(temp);
      temp = this.replaceManufacturerLinks(temp);
      return temp;
    },
  }
}
</script>

<style lang="scss">

#tableHead {
  z-index: 2;
}

#header {
  width: -webkit-fill-available;
  z-index: 2;
}

#header.sticky {
  top: 0;
  position: fixed;
  padding-right: 18px;
}

#components {
  display: block;
  padding-left: 224px;
}

.screen {
  text-align: -webkit-center;
  margin-top: 100px;
}

#pageHeader {
  padding-left: 217px;
}

#app {
  font-family: BloggerSansNormal,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: BloggerSans;
  src: url("../public/fonts/Blogger Sans-Bold.ttf");

}

@font-face {
  font-family: BloggerSansNormal;
  src: url("../public/fonts/Blogger Sans.ttf");
}

@font-face {
  font-family: BloggerSansLight;
  src: url("../public/fonts/Blogger Sans-Light.ttf");
}

button{
  font-family: BloggerSans,sans-serif !important;
}

input, select{
  font-family: BloggerSans,sans-serif !important
}

input[type=text]:not(.browser-default):disabled{
  color: black;
}

p.v-toast__text {
  font-family: BloggerSans,sans-serif;
}

.v-toast__item.v-toast__item--success {
  background-color: black;
}

.v-toast__item.v-toast__item--warning {
  background-color: red;
}

body {
  margin-top: 0;
  margin-right: 0;
}

</style>

<template>
  <div id="notAllowed">
    <select id="localeSwitcher" v-model="$i18n.locale" @change="changeLocalization">
      <option value="en">English</option>
      <option value="de">Deutsch</option>
    </select>
    <div class="warning">{{ this.$t("notAllowed.messageNotAllowed", [email, addinfo]) }}</div>
    <button type="button" id="goToLoginPage" @click="backToAuthorization">
      <label id="signIn" for="goToLoginPage">{{ $t("notAllowed.goToLoginPage") }}</label>
    </button>
  </div>
</template>

<script>
import $ from "jquery";
import Link from "@/components/utils/Link.vue";
import axios from "axios";

export default {
  name: "NotAllowedPage",
  data() {
    return {
      email: "",
      addinfo: ""
    }
  },
  mounted() {
    this.loadAdminInfo();
  },

  methods: {
    changeLocalization() {
      window.localStorage.setItem("locale", $('#localeSwitcher').val())
    },
    backToAuthorization() {
      this.$emit('authorization');
    },

    loadAdminInfo()
    {
      const headers = Link.methods.getHeaders();
      return axios.get(
          Link.methods.getSystemUserUrl(),
          {headers}
      ).then((res) => {
        const email = res.data.email;
        const fname = res.data.firstName;
        const lname = res.data.lastName;
        let addinfo = ''
        if (fname) {
          if (lname) {
            addinfo = '(' + lname + ', ' + fname + ')';
          } else {
            addinfo = '(' + fname + ')';
          }
        } else if (lname) {
          addinfo = '(' + lname + ')';
        }
        this.email = (email) ? '\n' + email : '';
        this.addinfo = (addinfo) ? '\n' + addinfo : '';
      }).catch((e) => {
        console.error(e);
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../public/materialize-src/sass/materialize.scss';
@import '../../public/styles/authorization_register.scss';

.warning {
  white-space: pre-line;
  font-size: 26px;
  padding-left: 10%;
  padding-right: 10%;
}

//select {
  //width: 10%;
  //display: block;
  //position: absolute;
  //border: 1px solid #ccc;
  //border-radius: 4px;
  //box-sizing: border-box;
  //padding: 4px 14px;
//}


</style>